


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SubscriptionPlanAddEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public subscriptionPlan: any = {
    options: {},
  };
  public subscriptionPlansOptions: any = {};

  public async created() {
    this.refresh();
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;
    if (this.id !== null) {
      this.$store.dispatch('loadSubscriptionPlanById', {
        subscriptionPlanId: this.id,
      }).then((response: any) => {
        this.subscriptionPlan = response;
        this.loading = false;
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    } else {
      this.loading = false;
    }

    this.$store.dispatch(
      'loadSubscriptionPlansOptions',
    ).then((response: any) => {
      this.subscriptionPlansOptions = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private setSubscriptionOption(key: string, value: any) {
    Vue.set(this.subscriptionPlan.options, key, value);
  }

  private onSubmit() {
    this.loading = true;

    const data: any = {
      payload: {
        title_fr: this.subscriptionPlan.title_fr,
        desc_fr: this.subscriptionPlan.desc_fr,
        title_en: this.subscriptionPlan.title_en,
        desc_en: this.subscriptionPlan.desc_en,
        options: this.subscriptionPlan.options,
      },
    };
    let name = 'editSubscriptionPlan';
    let method = 'mis à jour';
    if (this.id === null) {
      name = 'addSubscriptionPlan';
      method = 'ajouté';
    } else {
      data.subscriptionPlanId = this.id;
    }

    this.$store.dispatch(name, data)
    .then((response: any) => {
      this.$q.notify({
          message: `${response.title_fr} a été ${method}`,
          color: 'teal',
        });
      this.$router.push(`/admin/subscription-plan/${response.id}/edit`);
      this.subscriptionPlan = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }
}
